<ng-container *ngVar="(actionRD$ | async)?.payload as workflowAction">
  <div class="mt-1 mb-3">
    <ds-claimed-task-actions-loader *ngFor="let option of workflowAction?.options"
                                    [option]="option"
                                    [object]="object"
                                    (processCompleted)="this.processCompleted.emit($event)">
    </ds-claimed-task-actions-loader>

    <ng-container *ngIf="hasViewAction(workflowAction)">
      <button class="btn btn-primary workflow-view"
              ngbTooltip="{{'submission.workflow.generic.view-help' | translate}}"
              [routerLink]="[getWorkflowItemViewRoute((workflowitem$ | async))]">
        <span aria-hidden="true" class="fa fa-info-circle"></span> {{"submission.workflow.generic.view" | translate}}
      </button>
    </ng-container>

    <ds-claimed-task-actions-loader [option]="returnToPoolOption"
                                    [object]="object"
                                    (processCompleted)="this.processCompleted.emit($event)">
    </ds-claimed-task-actions-loader>
  </div>
</ng-container>
