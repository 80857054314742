<li class="sidebar-section">
    <a href="javascript:void(0);" class="nav-item nav-link shortcut-icon" [attr.aria-labelledby]="'sidebarName-'+section.id" [title]="('menu.section.icon.' + section.id) | translate" [routerLink]="itemModel.link">
        <span class="fas fa-{{section.icon}} fa-fw"></span>
        <span class="wb-inv">{{('menu.section.icon.' + section.id) | translate}}</span>
    </a>
    <div class="sidebar-collapsible">
        <span id="sidebarName-{{section.id}}" class="section-header-text">
        <a class="nav-item nav-link"  tabindex="-1" [routerLink]="itemModel.link">{{itemModel.text | translate}}</a>
        </span>
    </div>
</li>
