<ds-type-badge [useGcWeb]="useGcWeb" *ngIf="showLabel" [object]="dso"></ds-type-badge>
<p>
  <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
      rel="noopener noreferrer"
      [routerLink]="['/collections/' + dso.id]"
      [queryParams]="{ 'spc.sf': 'score', 'spc.sd': 'DESC','scope': dso.id,'spc.page':1 }"
      class="lead"
      [innerHTML]="dsoTitle"></a>
  <span *ngIf="linkType == linkTypes.None" class="lead" [innerHTML]="dsoTitle"></span>
</p>

<p *ngIf="dso.shortDescription" class="text-muted abstract-text"
  [innerHTML]="firstMetadataValue('dc.description.abstract')"></p>
