<!-- <form class="form-login"
      (ngSubmit)="submit()"
      [formGroup]="form" novalidate>

      <div class="form-group">
        <label for="email">{{"login.form.email" | translate}}
          <span *ngIf="(error | async) && hasError" class="login-alert" role="alert"
          @fadeOut>{{ (error | async) | translate }}</span>
          <span *ngIf="(message | async) && hasMessage" class="login-alert" role="alert"
          @fadeOut>{{ (message | async) | translate }}</span>
        </label>
        <div class="input-group">
          <div class="input-group-text"><span class="fas fa-envelope"></span></div>
          <input [attr.aria-label]="'login.form.email' |translate"
                autocomplete="off"
                autofocus
                class="form-control form-control-lg position-relative"
                formControlName="email"
                required
                id="email"
                type="email">
        </div>
      </div>
      <div class="form-group">
        <label for="password">{{"login.form.password" | translate}}
          <span *ngIf="(error | async) && hasError" class="login-alert" role="alert"
          @fadeOut>{{ (error | async) | translate }}</span>
          <span *ngIf="(message | async) && hasMessage" class="login-alert" role="alert"
          @fadeOut>{{ (message | async) | translate }}</span>
        </label>
        <div class="input-group">
          <div class="input-group-text"><span class="fas fa-key"></span></div>
          <input [attr.aria-label]="'login.form.password' |translate"
                autocomplete="off"
                class="form-control form-control-lg position-relative"
                formControlName="password"
                required
                id="password"
                type="password">
          </div>
      </div> -->
      <!-- Comment start
      <div *ngIf="(error | async) && hasError" class="alert alert-danger" role="alert"
           @fadeOut>{{ (error | async) | translate }}</div>
      <div *ngIf="(message | async) && hasMessage" class="alert alert-info" role="alert"
           @fadeOut>{{ (message | async) | translate }}</div>

 <label class="sr-only">{{"login.form.email" | translate}}</label>
  <input [attr.aria-label]="'login.form.email' |translate"
         autocomplete="off"
         autofocus
         class="form-control form-control-lg position-relative"
         formControlName="email"
         placeholder="{{'login.form.email' | translate}}"
         required
         type="email">
  <label class="sr-only">{{"login.form.password" | translate}}</label>
  <input [attr.aria-label]="'login.form.password' |translate"
         autocomplete="off"
         class="form-control form-control-lg position-relative mb-3"
         placeholder="{{'login.form.password' | translate}}"
         formControlName="password"
         required
         type="password">
  <div *ngIf="(error | async) && hasError" class="alert alert-danger" role="alert"
       @fadeOut>{{ (error | async) | translate }}</div>
  <div *ngIf="(message | async) && hasMessage" class="alert alert-info" role="alert"
       @fadeOut>{{ (message | async) | translate }}</div>
       Comment end
OSPR Change start
  <button class="btn btn-lg btn-primary btn-block mt-3" type="submit">
       <span aria-hidden="true" class="fas fa-sign-in-alt"></span> {{"login.form.submit" | translate}}
  </button>
OSPR Change end
</form> -->


<form role="form" (ngSubmit)="submit()" [formGroup]="form" novalidate>
  <div class="form-group">
    <label for="email" class="required">
      {{'sign.in.email' | translate}} <strong class="required">{{'sign.in.required' | translate}}</strong> 
      <span *ngIf="(error | async) && hasError" class="login-alert" role="alert"
      @fadeOut>{{ (error | async) | translate }}</span>
      <span *ngIf="(message | async) && hasMessage" class="login-alert" role="alert"
      @fadeOut>{{ (message | async) | translate }}</span>
    </label>
    <input 
      autocomplete="off" 
      autofocus 
      formControlName="email" 
      type="email" 
      class="form-control" 
      id="email" />
  </div>
  <div class="form-group">
    <label for="password" class="required">
      {{'sign.in.password' | translate}} <strong class="required">{{'sign.in.required' | translate}}</strong> 
      <span *ngIf="(error | async) && hasError" class="login-alert" role="alert"
      @fadeOut>{{ (error | async) | translate }}</span>
      <span *ngIf="(message | async) && hasMessage" class="login-alert" role="alert"
      @fadeOut>{{ (message | async) | translate }}</span>
    </label>
    <input 
        autocomplete="off" 
        required 
        formControlName="password" 
        type="password" 
        class="form-control" 
        id="password" />
  </div>

  <button type="submit" class="btn btn-primary">{{'login.title' | translate}}</button>
</form>
