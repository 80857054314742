<ng-container *ngVar="(bitstreams$ | async) as bitstreams">
  <h2 class="h3" *ngIf="bitstreams?.length > 0"  id="{{elementID}}">{{label | translate}}</h2>
  <div class="file-section"  *ngIf="bitstreams?.length > 0" >
    <ul class="download-links list-unstyled">
      <li *ngFor="let file of bitstreams; let last=last;">
        <ds-file-download-link  [bitstream]="file">
          <span>{{file?.name}}</span>
          <span>({{(file?.sizeBytes) | dsFileSize }})</span>
          <span *ngIf="!last" innerHTML="{{separator}}"></span>
        </ds-file-download-link>
      </li>
    </ul>

    <ds-loading *ngIf="isLoading" message="{{'loading.default' | translate}}" [showMessage]="false"></ds-loading>
    <div *ngIf="!isLastPage" class="mt-1" id="view-more">
      <a class="bitstream-view-more btn btn-outline-secondary btn-sm" [routerLink]="" (click)="getNextPage()">{{'item.page.bitstreams.view-more' | translate}}</a>
    </div>
    <div *ngIf="isLastPage && currentPage != 1" class="mt-1" id="collapse">
      <a class="bitstream-collapse btn btn-outline-secondary btn-sm" [routerLink]="" (click)="currentPage = undefined; getNextPage();">{{'item.page.bitstreams.collapse' | translate}}</a>
    </div>
  </div>
</ng-container>
