<ng-container *ngVar="(objects$ | async) as objects">
  <!-- OSPR Changes- modifying h3 to h1-->
  <!-- <h1 id="wb-cont" [ngClass]="{'sr-only': parentname }" >{{title | translate}}</h1> -->
  <!-- modified h3 to h1-->
  <section>
  <ng-container *ngComponentOutlet="getStartsWithComponent(); injector: objectInjector;"></ng-container>
  <div *ngIf="objects?.hasSucceeded && !objects?.isLoading && objects?.payload?.page.length > 0" @fadeIn>
    <div *ngIf="!enableArrows">
      <ds-viewable-collection
        [config]="paginationConfig"
        [sortConfig]="sortConfig"
        [objects]="objects">
      </ds-viewable-collection>
    </div>
    <div *ngIf="enableArrows">
      <!-- DSPR Sprint5 issue 248/249/250/251-->
      <!-- <div class="row">
        <div class="result-order-settings">
          <ds-sidebar-dropdown
                  *ngIf="sortConfig"
                  [id]="'search-sidebar-sort'"
                  [label]="'search.sidebar.settings.sort-by'"
                  (change)="reloadOrder($event)"
          >
            <option *ngFor="let direction of (sortDirections | dsKeys)"
                    [value]="sortConfig.field + ',' + direction.key"
                    [selected]="direction.value === (sortConfig.direction)? 'selected': null">
                {{'sorting.' + direction.key | translate}}
            </option>
          </ds-sidebar-dropdown>
        </div>
        <div class="page-size-settings">
          <ds-sidebar-dropdown
                  [id]="'search-sidebar-rpp'"
                  [label]="'search.sidebar.settings.rpp'"
                  (change)="reloadRPP($event)"
          >
            <option *ngFor="let pageSizeOption of paginationConfig?.pageSizeOptions"
                    [value]="pageSizeOption"
                    [selected]="pageSizeOption === +(paginationConfig.pageSize) ? 'selected': null">
                {{pageSizeOption}}
            </option>
          </ds-sidebar-dropdown>
        </div>
      </div> -->

    <h2 class="h4">{{('search.results.head' + (currentTerm ? '.term' : '')) | translate : {field: (('browse.metadata.' + browseField | translate)), term: currentTerm  } }}</h2>
   <div class="row well well-small mrgn-lft-0 mrgn-rght-0"> 
      <div class="col-md-8 pull-left">
         <div class="input-group">
            <label for="Sortby">{{'search.sidebar.settings.sort-by' | translate}}</label>
            <select (change)="reloadOrder($event)" class="form-control" id="Sortby" name="Sortby">
               <option 
                  *ngFor="let direction of (sortDirections | dsKeys)" 
                  [value]="sortConfig.field + ',' + direction.key" 
                  [selected]="direction.value === (sortConfig.direction)? 'selected': null" 
                  >
                {{ 'sorting.' + direction.key | translate }}
               </option>
            </select>
         </div>
      </div>
      <div class="col-md-4 pull-right">
         <div class="input-group">
            <label for="pageResults"> {{'search.sidebar.settings.rpp' | translate}} </label>
            <select (change)="reloadRPP($event)" class="form-control" id="pageResults" name="pageResults">
               <option label="Select"></option>
               <option *ngFor="let pageSizeOption of paginationConfig?.pageSizeOptions" [value]="pageSizeOption"
                [selected]="pageSizeOption === +(paginationConfig.pageSize) ? 'selected': null" 
                >
                {{pageSizeOption}}
              </option>
            </select>
         </div>
      </div>
   </div>

      <!--DSPR Sprint5 issue 248/249/250/251-->
      <ds-pagination
          [paginationOptions]="paginationConfig"
          [pageInfoState]="objects?.payload"
          [collectionSize]="objects?.payload?.totalElements"
          [sortOptions]="sortConfig"
          [hideGear]="true"
          [useGcWeb]="true"
          [hidePagerWhenSinglePage]="hidePagerWhenSinglePage"
          [hidePaginationDetail]="hidePaginationDetail"
          (pageChange)="onPageChange($event)"
          (pageSizeChange)="onPageSizeChange($event)"
          (sortDirectionChange)="onSortDirectionChange($event)"
          (sortFieldChange)="onSortFieldChange($event)"
          (paginationChange)="onPaginationChange($event)">
          
        <ul class="list-unstyled">
          <ng-container *ngFor="let object of objects?.payload?.page; let first = first;">
            <li *ngIf="!object.valueLang || (object.valueLang === locale.getCurrentLanguageCode())" class="mrgn-tp-md" [ngClass]="{'brdr-tp': !(browseField === 'author' || browseField === 'subject') }" >
              <ds-listable-object-component-loader [useGcWeb]="true" [object]="object"></ds-listable-object-component-loader>
            </li>
          </ng-container>
        </ul>
        
      </ds-pagination>
    </div>
  </div>
  <ds-loading *ngIf="!objects || objects?.isLoading" message="{{'loading.browse-by' | translate}}"></ds-loading>
  <!-- commeted to not show error message  instead showing generic message -->
  <!-- <ds-error *ngIf="objects?.hasFailed" message="{{'error.browse-by' | translate}}"></ds-error> -->
  <div *ngIf="objects?.hasFailed">
        {{ 'search.results.no-results'| translate}}
  </div>
  <div *ngIf="!objects?.isLoading && objects?.payload?.page.length === 0" class="w-100" role="alert">
    {{(('browse.empty') + (currentTerm ? '.term' : '')) | translate: {field: (('browse.metadata.' + browseField | translate)), term: currentTerm} }}
  </div>
  </section>
</ng-container> 