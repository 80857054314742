<div *ngIf="!useGcWeb else GcWebTemplate" class="page-size-settings">
    <ds-sidebar-dropdown
            [id]="'search-sidebar-rpp'"
            [label]="'search.sidebar.settings.rpp'"
            (change)="reloadRPP($event)"
    >
        <option *ngFor="let pageSizeOption of (paginationOptions$ | async).pageSizeOptions"
                [value]="pageSizeOption"
                [selected]="pageSizeOption === +((paginationOptions$ | async).pageSize) ? 'selected': null">
            {{pageSizeOption}}
        </option>
    </ds-sidebar-dropdown>
</div>

<ng-template #GcWebTemplate>
    <div class="input-group">
        <label class="text-nowrap" for="pageResults">{{'search.sidebar.settings.rpp' | translate}}</label>
        <select [attr.id]="'pageResults'" (change)="reloadRPP($event)" class="form-control" id="pageResults" name="pageResults">
            <option 
                *ngFor="let pageSizeOption of (paginationOptions$ | async)?.pageSizeOptions"
                [value]="pageSizeOption" [selected]="pageSizeOption === +((paginationOptions$ | async).pageSize)">{{pageSizeOption}}</option>
        </select>
    </div> 
</ng-template>