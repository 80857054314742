<div *ngIf="!useGcWeb else GcWebTemplate" class="labels">
    <ng-container *ngFor="let key of ((appliedFilters | async) | dsObjectKeys)">
        <ds-search-label *ngFor="let value of (appliedFilters | async)[key]" [inPlaceSearch]="inPlaceSearch" [key]="key" [value]="value" [appliedFilters]="appliedFilters"></ds-search-label>
    </ng-container>
</div>

<ng-template #GcWebTemplate>
    <ng-container *ngIf="((appliedFilters | async) | dsObjectKeys).length">
        <h3 class="panel-title h5">{{'search.filters.applied' | translate}}</h3>
        <ul class="list-unstyled list-inline">
            <ng-container *ngFor="let key of ((appliedFilters | async) | dsObjectKeys)">
                <li *ngFor="let value of (appliedFilters | async)[key]">
                    <ds-search-label  [inPlaceSearch]="inPlaceSearch" [key]="key"
                        [useGcWeb]="useGcWeb"
                        [value]="value" [appliedFilters]="appliedFilters"></ds-search-label>
                </li>
            </ng-container>
        </ul>
    </ng-container>
</ng-template>
