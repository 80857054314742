<!-- <button class="btn btn-primary mt-1 mb-3"
            ngbTooltip="{{'submission.workflow.generic.view-help' | translate}}"
            [routerLink]="[itemPageRoute]">
  <span aria-hidden="true" class="fa fa-info-circle"></span> {{"submission.workflow.generic.view" | translate}}
</button> -->

<a 
  class="btn btn-primary mt-1 mb-3"
  ngbTooltip="{{'submission.workflow.generic.view-help' | translate}}"
  [routerLink]="[itemPageRoute]"
  role="button">
  <span aria-hidden="true" class="fa fa-info-circle"></span> {{"submission.workflow.generic.view" | translate}}
</a>
