<a *ngIf="!useGcWeb else GcWebTemplate" class="badge badge-primary mr-1 mb-1 text-capitalize"
   [routerLink]="searchLink"
   [queryParams]="(removeParameters | async)" queryParamsHandling="merge">
   {{('search.filters.applied.' + key) | translate}}: {{ normalizeFilterValue(value) | translate }}
    <span> ×</span>
</a>

<ng-template #GcWebTemplate>
        <a [routerLink]="searchLink" [queryParams]="(removeParameters | async)" queryParamsHandling="merge">
            {{('search.filters.applied.' + key) | translate}}: {{ normalizeFilterValue(value) | translate }}
            <span class="fa fa-times text-danger"> <span class="wb-inv">{{'search.filters.remove' | translate}}</span></span> </a>
</ng-template>
