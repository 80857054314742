import { combineLatest as observableCombineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FacetValue } from '../../../../facet-value.model';
import { SearchFilterConfig } from '../../../../search-filter-config.model';
import { SearchService } from '../../../../../../core/shared/search/search.service';
import { SearchFilterService } from '../../../../../../core/shared/search/search-filter.service';
import { SearchConfigurationService } from '../../../../../../core/shared/search/search-configuration.service';
import { hasValue } from '../../../../../empty.util';
import { currentPath } from '../../../../../utils/route.utils';
import { getFacetValueForType } from '../../../../search.utils';
import { PaginationService } from '../../../../../../core/pagination/pagination.service';

@Component({
  selector: 'ds-search-facet-option',
  styleUrls: ['./search-facet-option.component.scss'],
  templateUrl: './search-facet-option.component.html',
})

/**
 * Represents a single option in a filter facet
 */
export class SearchFacetOptionComponent implements OnInit, OnDestroy {
  /**
   * A single value for this component
   */
  @Input() filterValue: FacetValue;

  /**
   * The filter configuration for this facet option
   */
  @Input() filterConfig: SearchFilterConfig;

  /**
   * Emits the active values for this filter
   */
  @Input() selectedValues$: Observable<FacetValue[]>;

  /**
   * True when the search component should show results on the current page
   */
  @Input() inPlaceSearch;

  /**
   * Use GC Web Template
   */
  @Input() useGcWeb = false;

  /**
   * Use GC Web Template
   */
  @Input() selfRef;

  /**
   * Name of facet
   */
  @Input() facetTerm;

  /**
   * Emits true when this option should be visible and false when it should be invisible
   */
  isVisible: Observable<boolean>;

  /**
   * UI parameters when this filter is added
   */
  addQueryParams;

  /**
   * Link to the search page
   */
  searchLink: string;
  /**
   * Subscription to unsubscribe from on destroy
   */
  sub: Subscription;

  paginationId: string;

  constructor(protected searchService: SearchService,
              protected filterService: SearchFilterService,
              protected searchConfigService: SearchConfigurationService,
              protected router: Router,
              protected paginationService: PaginationService
  ) {
  }

  /**
   * Initializes all observable instance variables and starts listening to them
   */
  ngOnInit(): void {
    this.paginationId = this.searchConfigService.paginationID;
    this.searchLink = this.getSearchLink();
    this.isVisible = this.isChecked().pipe(map((checked: boolean) => !checked));
    this.isVisible.subscribe(x => {
      if(!x && this.selfRef) {
        (<HTMLElement>this.selfRef).remove();
      }
    })
    this.sub = observableCombineLatest(this.selectedValues$, this.searchConfigService.searchOptions)
      .subscribe(([selectedValues, searchOptions]) => {
        this.updateAddParams(selectedValues);
      });
  }

  /**
   * Checks if a value for this filter is currently active
   */
  private isChecked(): Observable<boolean> {
    return this.filterService.isFilterActiveWithValue(this.filterConfig.paramName, this.getFacetValue());
  }

  /**
   * @returns {string} The base path to the search page, or the current page when inPlaceSearch is true
   */
  private getSearchLink(): string {
    if (this.inPlaceSearch) {
      return currentPath(this.router);
    }
    return this.searchService.getSearchLink();
  }

  /**
   * Calculates the parameters that should change if a given value for this filter would be added to the active filters
   * @param {string[]} selectedValues The values that are currently selected for this filter
   */
  private updateAddParams(selectedValues: FacetValue[]): void {
    const page = this.paginationService.getPageParam(this.searchConfigService.paginationID);
    this.addQueryParams = {
      [this.filterConfig.paramName]: [...selectedValues.map((facetValue: FacetValue) => getFacetValueForType(facetValue, this.filterConfig)), this.getFacetValue()],
      [page]: 1
    };
  }

  /**
   * TODO to review after https://github.com/DSpace/dspace-angular/issues/368 is resolved
   * Retrieve facet value related to facet type
   */
  private getFacetValue(): string {
    return getFacetValueForType(this.filterValue, this.filterConfig);
  }

  /**
   * Make sure the subscription is unsubscribed from when this component is destroyed
   */
  ngOnDestroy(): void {
    if (hasValue(this.sub)) {
      this.sub.unsubscribe();
    }
  }

  onSelect(event: any, value: any, facetName: string) {
    const isChecked = event.target.checked;
    //this.router.navigate([this.searchLink], {queryParamsHandling : 'merge', queryParams: this.addQueryParams})
    // FORSC Changes to apply filter on button click
    const filterSelections = this.filterService.getSelectedFilters() ?? [];
     // Create a copy of filterSelections
    const modifiedFilters = { ...filterSelections };

    this.filterConfig.name = facetName;
    if (isChecked) {
      //this.updateAddParams([value]);
      Object.keys(filterSelections).forEach(key =>{
        if(key.indexOf('f.')> -1)
        {
          if (this.addQueryParams[key]) {
            this.addQueryParams[key] =[... filterSelections[key] , ... this.addQueryParams[key]];
          }
          else {
            if (filterSelections[key]) {
              this.addQueryParams[key] = [...filterSelections[key]];
            }
          }
        }
      })
      this.filterService.selectedFilterOptions$.next(this.addQueryParams);
    }
    else {
      Object.keys(modifiedFilters).forEach(key => {
        if (key === `f.${facetName}` && key.indexOf('f.') > -1) {
          modifiedFilters[key] = modifiedFilters[key].filter(item => item !== `${value.value},equals`);
        }
      });
      this.filterService.selectedFilterOptions$.next(modifiedFilters);
    }
  }

  isFacetOptionChecked(filterValue: any, facetName: string): boolean {
    const filterSelections = this.filterService.getSelectedFilters() ?? [];
    // Constructing the expected filter value format
    const expectedFilterValue = `${filterValue.value},equals`;
    let isChecked = false;
    Object.keys(filterSelections).forEach(key => {
      if (key === `f.${facetName}` && key.indexOf(facetName)> -1) {
        const found = filterSelections[key].find(item => item === expectedFilterValue);
        if (found) {
          isChecked = true;
        }
      }
    });
    return isChecked;
  }

  formatID(value) {
    return value
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9 ,]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .join('-')
    .toLowerCase();
   }
  
}
