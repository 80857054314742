<form class="mrgn-tp-md" role="form" [formGroup]="form">
  <ng-container *ngFor="let row of rows.controls; let i = index" formArrayName="rows">
    <div class="d-flex flex-wrap mrgn-bttm-sm" [formGroupName]="i">
      <select id="filter-item-{{i+1}}" name="filter-item-{{i+1}}" class="form-control mrgn-rght-sm" attr.aria-label="{{'comcol.search.filter.aria.filter.item' | translate}}"  formControlName="filtertype" required>
        <option [attr.aria-disabled]="'true'" [disabled]="true" value="">{{'comcol.search.filter.item.select' | translate}}</option>
        <option value="alltitles">{{'comcol.search.filter.item.title' | translate}}</option>
        <option value="allauthors">{{'comcol.search.filter.item.author' | translate}}</option>
        <option value="subject">{{'comcol.search.filter.item.subject' | translate}}</option>
        <option value="dateIssued">{{'comcol.search.filter.item.date.issued' | translate}}</option>
        <option value="nrcan.nts">{{'comcol.search.filter.item.nts' | translate}}</option>
        <option value="sourcetitle">{{'comcol.search.filter.item.source.title' | translate}}</option>
        <option value="dc.description">{{'comcol.search.filter.item.abstdesckw' | translate}}</option>
        <option value="dc.identifier.doi">DOI</option>
        <option value="nrcan.reportnumber">{{'comcol.search.filter.item.volrpt.number'| translate}}</option>
      </select>

      <select id="filter-operator-{{i+1}}" name="filter-operator-{{i+1}}" class="form-control mrgn-rght-sm" attr.aria-label="{{'comcol.search.filter.aria.filter.operator' | translate}}" formControlName="relationalOperator" required>
        <option value="contains" selected>{{'comcol.search.filter.operator.contains' | translate}}</option>
        <option value="notcontains">{{'comcol.search.filter.operator.not.contains' | translate}}</option>
      </select>

      <!-- input class="mrgn-rght-sm form-control flex-grow-1" id="filter-item-info-{{i+1}}" name="filter-item-info-{{i+1}}" type="text" aria-label="Filter Value" formControlName="filter" -->
      <ng-container [ngSwitch]="row.get('filtertype')?.value">
        <input *ngSwitchDefault type="text" name="filterValue" id="filter-item-info-{{i+1}}" aria-label="Filter Value" formControlName="filter"
          class="mrgn-rght-sm form-control flex-grow-1"/>
        <select *ngSwitchCase="'subject'" name="filterValue" id="filter-item-infos-{{i+1}}" aria-label="Filter Value" formControlName="filter" class="mrgn-rght-sm form-control flex-grow-1">
          <option value="Arts, Music, Literature">{{"Arts, Music, Literature"|translate}}</option>
          <option value="Agriculture">{{"Agriculture"|translate}}</option>
          <option value="Economics and Industry">{{"Economics and Industry"|translate}}</option>
          <option value="Education and Training">{{"Education and Training"|translate}}</option>
          <option value="Form descriptors">{{"Form descriptors"|translate}}</option>
          <option value="Government and Politics">{{"Government and Politics"|translate}}</option>
          <option value="Health and Safety">{{"Health and Safety"|translate}}</option>
          <option value="History and Archaeology">{{"History and Archaeology"|translate}}</option>
          <option value="Information and Communications">{{"Information and Communications"|translate}}</option>
          <option value="Labour">{{"Labour"|translate}}</option>
          <option value="Language and Linguistics ">{{"Language and Linguistics "|translate}}</option>
          <option value="Law">{{"Law"|translate}}</option>
          <option value="Military">{{"Military"|translate}}</option>
          <option value="Nature and Environment">{{"Nature and Environment"|translate}}</option>
          <option value="Persons">{{"Persons"|translate}}</option>
          <option value="Processes">{{"Processes"|translate}}</option>
          <option value="Society and Culture">{{"Society and Culture"|translate}}</option>
          <option value="Science and Technology">{{"Science and Technology"|translate}}</option>
          <option value="Transport">{{"Transport"|translate}}</option>
          <option value="Air">{{"Air"|translate}}</option>
          <option value="Biodiversity">{{"Biodiversity"|translate}}</option>
          <option value="Climate">{{"Climate"|translate}}</option>
          <option value="Economy and industry">{{"Economy and industry"|translate}}</option>
          <option value="Health">{{"Health"|translate}}</option>
          <option value="Land">{{"Land"|translate}}</option>
          <option value="Water">{{"Water"|translate}}</option>
        </select>
      </ng-container>

      <button type="button" (click)="addRow()" class="btn btn-default btn-sm mrgn-rght-sm" >
        <span class="glyphicon glyphicon-plus"></span><span class="wb-inv"> + </span>
      </button>

      <button type="button" (click)="removeRow(i)" class="btn btn-default btn-sm">
        <span class="glyphicon glyphicon-remove"></span> <span class="wb-inv">remove</span></button>

    </div>
  </ng-container>
  <div class="mrgn-tp-md">
    <button id="apply-filters" name="submit-apply-filters" type="button"  class="btn btn-primary mrgn-rght-md" (click)="addRow()"> + </button>
    <button id="reset-filters" name="reset-filters" type="button" class="btn btn-default" (click)="resetFilters()">
      <span class="fa fa-undo" aria-hidden="true"></span> {{'comcol.search.filter.button.clear.search'|translate}}</button>
    <a href="/home" id="backhome" name="backhome"  role="button" class="ml-xl-3">{{'comcol.search.filter.button.basic.search'|translate}}</a>
      <!--<span class="fa fa-times" aria-hidden="true"></span> {{'comcol.search.filter.button.cancel' | translate}}</a> -->
  <!--
    <button id="debug" name="debug" type="button" class="btn btn-default" (click)="printFormValues()"><span class="fa fa-undo" aria-hidden="true"></span> Debug </button>
    -->

  <!-- new div for Advanced Search Tutorial -->
  <div class="mrgn-tp-md  float-right ml-3">
    <a   *ngIf="translate.currentLang === 'en'"  href="https://ostrassets.blob.core.windows.net/publicassets/EnglishAdvancedSearch.html"
          target="_blank" class="d-flex align-items-center">
      <img alt="Basic Search Tutorial" class="img-responsive mr-1" src="assets/images/video.png">
      {{"home.advancedsearchtutorial"| translate}}
    </a>
    <a   *ngIf="translate.currentLang === 'fr'"  href="https://ostrassets.blob.core.windows.net/publicassets/EnglishAdvancedSearch.html"
          target="_blank" class="d-flex align-items-center">
      <img alt="Basic Search Tutorial" class="img-responsive mr-1" src="assets/images/video.png">
      {{"home.advancedsearchtutorial"| translate}}
    </a>
  </div>
  </div>
</form>
<!-- debug
<div>
  <h2>
  search string: <span class="output" > {{output}}</span>
  </h2>
</div>
-->
