<div class="btn-group" data-toggle="buttons">
    <a *ngIf="isToShow(viewModeEnum.ListElement)"
       routerLink="."
       [queryParams]="{view: 'list'}"
       queryParamsHandling="merge"
       (click)="switchViewTo(viewModeEnum.ListElement)"
       routerLinkActive="active"
       [class.active]="currentMode === viewModeEnum.ListElement"
       class="btn btn-secondary">
         <!-- OSPR Changes: modify to visually hidden per WET guidelines -->
         <!-- <i class="fas fa-list" title="{{'search.view-switch.show-list' | translate}}"></i> -->
         <span aria-hidden="true" class="fas fa-list" title="{{'search.view-switch.show-list' | translate}}"></span>
         <span class="wb-inv">{{'search.view-switch.show-list' | translate}}</span>
         <!-- End OSPR Changes -->
    </a>
    <a *ngIf="isToShow(viewModeEnum.GridElement)"
       routerLink="."
       [queryParams]="{view: 'grid'}"
       queryParamsHandling="merge"
       (click)="switchViewTo(viewModeEnum.GridElement)"
       routerLinkActive="active"
       [class.active]="currentMode === viewModeEnum.GridElement"
       class="btn btn-secondary">
        <!-- OSPR Changes: modify to visually hidden per WET guidelines -->
        <!-- <i class="fas fa-th-large" title="{{'search.view-switch.show-grid' | translate}}"></i> -->
        <span aria-hidden="true" class="fas fa-th-large" title="{{'search.view-switch.show-grid' | translate}}"></span>
        <span class="wb-inv">{{'search.view-switch.show-grid' | translate}}</span>
        <!-- End OSPR Changes -->
    </a>
    <a *ngIf="isToShow(viewModeEnum.DetailedListElement)"
       routerLink="."
       [queryParams]="{view: 'detail'}"
       queryParamsHandling="merge"
       (click)="switchViewTo(viewModeEnum.DetailedListElement)"
       routerLinkActive="active"
       [class.active]="currentMode === viewModeEnum.DetailedListElement"
       class="btn btn-secondary">
        <!-- OSPR Changes: modify to visually hidden per WET guidelines -->
        <!-- <i class="far fa-square" title="{{'search.view-switch.show-detail' | translate}}"></i> -->
        <span aria-hidden="true" class="far fa-square" title="{{'search.view-switch.show-detail' | translate}}"></span>
        <span class="wb-inv">{{'search.view-switch.show-detail' | translate}}</span>
        <!-- End OSPR Changes -->

    </a>
</div>
