
  <!-- following is for layer icon -->
  <link
    rel="stylesheet"
    href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
    integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
    crossorigin=""
  />
    <!-- follow is for leaflet draw -->
  <link
    rel="stylesheet"
    href="https://unpkg.com/leaflet-draw@latest/dist/leaflet.draw-src.css"
  />

<div class="container">
    <!--<div class="col-xs-12 col-md-6"> -->
    <div class="row">
      <div class="col-xs-12 col-md-8 d-flex align-items-left justify-content-left">
        <div class="map"  id="map" style="width: 600px; height: 350px;"></div>

      </div>

      <div class="col-sm-12 col-md-4 d-flex flex-column ">
        <div class="row align-items-center justify-content-start form-control-static">
          <div class="col-xs-12 col-sm-5">{{'geo-aoi-west'|translate}}:</div>
          <div class="col-xs-12 col-sm-7">
            <input class="form-control l form-control-sm" type="text"
              value="{{ lng1 }}" title="Latitude" placeholder="" />
          </div>
        </div>
        <div class="row align-items-center justify-content-start form-control-static">
          <div class="col-xs-12 col-sm-5">{{'geo-aoi-east'|translate}}:</div>
          <div class="col-xs-12 col-sm-7">
            <input class="form-control l form-control-sm" type="text"
              value="{{ lng2 }}" title="Latitude" placeholder="" />
          </div>
        </div>
        <div class="row align-items-center justify-content-start form-control-static">
          <div class="col-xs-12 col-sm-5">{{'geo-aoi-north'|translate}}:</div>
          <div class="col-xs-12 col-sm-7">
            <input class="form-control l form-control-sm" type="text"
              value="{{ lat2 }}" title="Latitude" placeholder="" />
          </div>
        </div>
        <div class="row align-items-center justify-content-start form-control-static">
          <div class="col-xs-12 col-sm-5">{{'geo-aoi-south'|translate}}:</div>
          <div class="col-xs-12 col-sm-7">
            <input class="form-control l form-control-sm" type="text"
              value="{{ lat1 }}" title="Latitude" placeholder="" />
          </div>
        </div>

      </div>
    </div>
    <!-- Old NWES
    <div class="row d-flex align-items-center justify-content-center">
      <div class="mx-0 col-12 ">
        <div class="row"><div class="col-12"> &nbsp;</div></div>
        <!- North  ->
        <div class="row">
          <div class="col-sm-4"></div>
          <div class="col-sm-1 d-flex align-items-center">North:</div>
          <div class="col-sm-2">
            <input
              class="form-control l form-control-sm"
              type="text"
              value="{{ lat2 }}"
            />
          </div>
        </div> -->
        <!-- space row -->
        <div class="row"><div class="col-12"> &nbsp;</div></div>
        <!-- East and West ->
        <div class="row">
          <div class="col-sm-2"> &nbsp; </div>
          <div class="col-sm-1 d-flex align-items-center">West:</div>
          <div class="col-sm-2">
            <input
              class="form-control l form-control-sm"
              type="text"
              value="{{ lng1 }}"
            />
          </div>

          <div class="col-sm-1"></div>
          <div class="col-sm-1 d-flex align-items-center">East:</div>
          <div class="col-sm-2">
            <input
              class="form-control l form-control-sm"
              type="text"
              value = "{{ lng2}}"
            />
          </div>
        </div>
        <div class="row"><div class="col-12"> &nbsp;</div></div>
        <!- South ->
        <div class="row">
          <div class="col-sm-4"></div>
          <div class="col-sm-1 d-flex align-items-center">South:</div>
          <div class="col-sm-2">
            <input
              class="form-control l form-control-sm"
              type="text"
              value="{{ lat1 }}"
            />
          </div>
          <div class="col-sm-2"></div>

        </div>
      </div>
    </div>
    -->
</div>
