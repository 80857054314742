<div class="container">
    <div class="mx-0 col-12">
        <h3 class="col-8 px-0 height">{{'tips-for-search.head' | translate}}</h3>
        <h5 class="px-0 mt-0 col-12">{{'tips-for-search.ospr.title' | translate}}</h5>
        <p>{{'tips-for-search.ospr.text' | translate}}</p>
        <hr class="solid">
        <h5 class="px-0 mt-0 col-12">{{'tips-for-search.faceted.title' | translate}}</h5>
        <p>{{'tips-for-search.faceted.text' | translate}}</p>
        <hr class="solid">
        <h5 class="px-0 mt-0 col-12">{{'tips-for-search.advanced.title' | translate}}</h5>
        <p>{{'tips-for-search.advanced.text' | translate}}</p>
        <hr class="solid">
        <h5 class="px-0 mt-0 col-12">{{'tips-for-search.other.title' | translate}}</h5>
        <p>{{'tips-for-search.other.text' | translate}}</p>
        <hr class="solid">
        <br/>
        <p class="text-center">
          <a routerLink="/home" class="btn btn-primary">{{"404.link.home-page" | translate}}</a>
        </p>      
    </div>
</div>
