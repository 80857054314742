<a *ngIf="!(formCollapsed | async)"
   class="close position-relative"
   ngbTooltip="{{'form.group-collapse-help' | translate}}"
   placement="left">
   
  <span class="fas fa-angle-up fa-fw fa-2x"
        (click)="collapseForm()"></span>
</a>
<a *ngIf="(formCollapsed | async)"
   class="close position-relative"
   ngbTooltip="{{'form.group-expand-help' | translate}}"
   placement="left">
  <span class="fas fa-angle-down fa-fw fa-2x"
        (click)="expandForm()"></span>
</a>

<div class="pt-2" [ngClass]="{'border-top': !showErrorMessages, 'border border-danger': showErrorMessages}">
  <div *ngIf="!(formCollapsed | async)" class="pl-2 row"  @shrinkInOut>
    <ds-form #formRef="formComponent"
             class="col-sm-12 col-md-8 col-lg-9 col-xl-10 pl-0"
             [formId]="formId"
             [formModel]="formModel"
             [displaySubmit]="false"
             [displayCancel]="false"
             [emitChange]="false"
             (dfBlur)="onBlur($event)"
             (dfFocus)="onFocus($event)"></ds-form>


    <div *ngIf="!(formCollapsed | async)" class="col p-0 m-0 d-flex justify-content-center align-items-center">

        <button type="button"
                class="btn btn-link"
                [disabled]="isMandatoryFieldEmpty()"
                (click)="save()">    
          <span class="fas fa-save text-primary fa-2x" aria-hidden="true"></span>
          <span class="wb-inv">{{'form.save' | translate}}</span>
        </button>
        <button type="button"
                class="btn btn-link"
                [disabled]="!editMode"
                (click)="delete()">
          <span class="fas fa-trash text-danger fa-2x"
             aria-hidden="true"></span>
          <span class="wb-inv">{{'form.remove' | translate}}</span>

        </button>
        <button type="button"
                class="btn btn-link"
                [disabled]="isMandatoryFieldEmpty()"
                (click)="clear()">
          <span class="fas fa-undo fa-2x"
             aria-hidden="true"></span>
             <span class="wb-inv">{{'form.undo' | translate}}</span>
        </button>

    </div>
    <div class="clearfix"></div>
  </div>

  <div class="d-flex">
    <ds-loading *ngIf="!chips" [showMessage]="false"></ds-loading>
    <div *ngIf="chips && !chips.hasItems()">
      <input type="text"
             class="border-0 form-control-plaintext tag-input mt-1 mb-1 pl-2 text-muted"
             readonly
             tabindex="-1"
             value="{{'form.no-value' | translate}}">
    </div>
    <ds-chips
      *ngIf="chips && chips.hasItems()"
      [chips]="chips"
      [editable]="true"
      [showIcons]="true"
      (selected)="onChipSelected($event)"></ds-chips>
  </div>
</div>
