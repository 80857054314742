import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-search-tips-page',
  templateUrl: './search-tips-page.component.html',
  styleUrls: ['./search-tips-page.component.scss']
})
export class SearchTipsPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
