<div *ngIf="!useGcWeb else GcWebTemplate" class="simple-view-element" [class.d-none]="hideIfNoTextContent && content.textContent.trim().length === 0">
  <h3 class="simple-view-element-header" *ngIf="label && !subheading">{{ label }}</h3>
  <h3 class="simple-view-element-header" *ngIf="label && subheading">{{ label }}</h3>
  <div #content class="simple-view-element-body">
    <ng-container [ngTemplateOutlet]="projectedContent"></ng-container>
  </div>
</div>

<ng-template #GcWebTemplate>
  <ng-container>
    <div class="simple-view-element" [class.d-none]="hideIfNoTextContent && noContent">
      <span *ngIf="!((hideIfNoTextContent && noContent)) && label" class="simple-view-element-header h3">{{label}}</span>
      <div #GcContent>
        <ng-container [ngTemplateOutlet]="projectedContent"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>



<ng-template #projectedContent>
    <ng-content></ng-content>
</ng-template>
