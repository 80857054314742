<ng-container *ngIf="!useGcWeb else GCWebTemplate">
  <form #form="ngForm" (ngSubmit)="onSubmit(value)"
        [action]="action" (keydown)="onKeydown($event)"
        (keydown.arrowdown)="shiftFocusDown($event)"
        (keydown.arrowup)="shiftFocusUp($event)" (keydown.esc)="close()"
        (dsClickOutside)="close();">
    <div class="form-group mb-0">
      <label *ngIf="label; else searchInput">
        <span class="font-weight-bold">
          {{label}}
        </span>
        <ng-container *ngTemplateOutlet="searchInput"></ng-container>
      </label>
    </div>
    <ng-template #searchInput>
      <input #inputField type="text" [(ngModel)]="value" [name]="name"
             class="form-control suggestion_input"
             [ngClass]="{'is-invalid': !valid}"
             [dsDebounce]="debounceTime" (onDebounce)="find($event)"
             [placeholder]="placeholder"
             [ngModelOptions]="{standalone: true}" autocomplete="off"
             (keydown)="onKeyDown($event)"
      />
    </ng-template>
    <label class="d-none">
      <input type="submit"/>
      <span>{{'search.filters.search.submit' | translate}}</span>
    </label>
    <div class="autocomplete dropdown-menu" [ngClass]="{'show': (show | async) && isNotEmpty(suggestions)}">
      <div class="dropdown-list">
        <div *ngFor="let suggestionOption of suggestions">
          <a href="#" class="d-block dropdown-item"  (click)="onClickSuggestion(suggestionOption.value)" #suggestion>
            <span [innerHTML]="suggestionOption.displayValue"></span>
          </a>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #GCWebTemplate>
  <form #form="ngForm" (ngSubmit)="onSubmit(value)"
  [action]="action" (keydown)="onKeydown($event)"
  (keydown.arrowdown)="shiftFocusDown($event)"
  (keydown.arrowup)="shiftFocusUp($event)" (keydown.esc)="close()"
  (dsClickOutside)="close();">
  <label [for]="'search-input-' + name">{{placeholder | translate}}</label>
  <div class="input-group">
    <input [(ngModel)]="value"
    #inputField
    [ngModelOptions]="{standalone: true}" autocomplete="off"
    [dsDebounce]="debounceTime" 
    (onDebounce)="find($event)"
    (keydown)="onKeyDown($event)"
    [name]="name"
    [ngModelOptions]="{standalone: true}"
    (dsClickOutside)="close();" type="text" class="form-control" [attr.id]=" 'search-input-' + name" 
     />
    <!-- Commenting as per DSPACE 7.6 -->
    <!-- <div class="input-group-btn">
      <button class="btn btn-default" >
        <span class="fa-search fa"></span>
        <span class="wb-inv">{{placeholder | translate}}</span>
      </button>
    </div> -->
    <div class="autocomplete dropdown-menu" [ngClass]="{'show': (show | async) && isNotEmpty(suggestions)}">
      <div class="dropdown-list">
        <ul>
          <li *ngFor="let suggestionOption of suggestions">
            <a href="#" class="d-block dropdown-item" (click)="onClickSuggestion(suggestionOption.value)" #suggestion>
              <span [innerHTML]="suggestionOption.displayValue"></span>
            </a>
          </li>
        </ul>
        </div>
      </div>
    </div>
    </form>
</ng-template>