<ng-container *ngIf="moreThanOneLanguage">
  <h2 class="wb-inv">{{'nav.lang.selection'| translate}}</h2>
  <ul class="list-inline mrgn-bttm-0">
    <li>
      <a 
        [routerLink] = "[getCurrentUrl()]"
        [queryParams] = "{useLang: getAlternateLanguageCode(this.translate.currentLang)}"
        queryParamsHandling="merge"
        [attr.hrefLang]="langLabel(getAlternateLanguageCode(this.translate.currentLang))"
        [attr.lang]="'nav.language.attribute' | translate" [attr.aria-label]="'nav.language' | translate"
        [title]="'nav.language' | translate"
        tabindex="0">
        <span class="hidden-xs" [attr.translate]="'no'">
          {{ langLabel(getAlternateLanguageCode(this.translate.currentLang)) }}
        </span>
        <abbr [attr.translate]="'no'" title="{{ langLabel(getAlternateLanguageCode(this.translate.currentLang)) }}"
          class="visible-xs h3 mrgn-tp-sm mrgn-bttm-0 text-uppercase">{{ ('nav.language.attribute' | translate)
          }}</abbr>
      </a>
    </li>
  </ul>
</ng-container>