<!DOCTYPE html>
<html lang="en" class="js backgroundsize borderimage csstransitions fontface svg details progressbar meter mathml cors smallview">
	<head>
		<meta charset="utf-8"/>
		<title>Federal Open Science Repository of Canada (FOSRC) / Le Dépôt fédéral de science ouverte du Canada (DFSOC)</title>
		<link rel="schema.dcterms" href="http://purl.org/dc/terms/" />
		<meta content="width=device-width, initial-scale=1" name="viewport" />
		<meta name="description" content="The Government of Canada website is a single point of access to all programs, services, departments, ministries and organizations of the Government of Canada." />
		<meta name="dcterms.description" content="The Government of Canada website is a single point of access to all programs, services, departments, ministries and organizations of the Government of Canada." />
		<meta name="dcterms.creator" content="Government of Canada" />
		<meta name="dcterms.title" content="Federal Open Science Repository of Canada" />
		<meta name="dcterms.issued" title="W3CDTF" content="2024" />
		<meta name="dcterms.modified" title="W3CDTF" content="2024" />
		<meta name="dcterms.subject" content="Science and Technology" />
		<meta name="dcterms.language" title="ISO639-2" content="English and French" />
		<meta name="robots" content="noindex, follow" />
		<meta property="dcterms:issued" content="2024">
		<meta property="dcterms:modified" content="2024">
		<meta property="dcterms:title" lang="en" content="Federal Open Science Repository of Canada" />
		<meta property="dcterms:creator" lang="en" content="Government of Canada">
		<meta property="dcterms:subject" lang="en" title="gccore" content="Science and Technology" />
		<meta property="dcterms:description" lang="en" content="The Government of Canada website is a single point of access to all programs, services, departments, ministries and organizations of the Government of Canada." />
		<meta property="dcterms:title" lang="fr" content="Le Dépôt fédéral de science ouverte du Canada" />
		<meta property="dcterms:creator" lang="fr" content="Gouvernement du Canada">
		<meta property="dcterms:subject" lang="fr" title="gccore" content="Sciences et technology" />
		<meta property="dcterms:description" lang="fr" content="Le site Web du gouvernement du Canada fournit un point d'accès complet à tous les programmes, services, départements, ministères et organismes du gouvernement du Canada." />

		<!-- the following links are provided by welcome-page.component.ts -->

		<!-- <link rel="apple-touch-icon" sizes="57x57 72x72 114x114 144x144 150x150" class="wb-favicon" href="https://wet-boew.github.io/themes-dist/GCWeb/GCWeb/assets/favicon-mobile.png"> -->

		<!-- <link href="https://wet-boew.github.io/themes-dist/GCWeb/GCWeb/assets/favicon.ico" rel="icon" type="image/x-icon" class="wb-init wb-favicon-inited"> -->

		<!-- <link rel="stylesheet" href="https://wet-boew.github.io/themes-dist/GCWeb/GCWeb/css/theme.min.css"> -->

		<!-- <noscript>
			<link rel="stylesheet" href="https://wet-boew.github.io/themes-dist/GCWeb/wet-boew/css/noscript.min.css" />
		</noscript> -->

		<!-- <link rel="stylesheet" href="https://wet-boew.github.io/themes-dist/GCWeb/GCWeb/css/messages.min.css"> -->
	</head>

	<body *ngIf="isLoadingResources === false" class="splash" vocab="http://schema.org/" resource="#wb-webpage" typeof="WebPage">

		<div id="bg" data-wb-randomize='{ "shuffle": true, "selector": "img", "toggle": "hide" }'>
			<img class="hide" src="assets/images/sp-bg-1.jpg" alt="">
			<img class="hide" src="assets/images/sp-bg-2.jpg" alt="">
			<img class="hide" src="assets/images/sp-bg-3.jpg" alt="">
			<img class="hide" src="assets/images/sp-bg-4.jpg" alt="">
			<img class="hide" src="assets/images/sp-bg-5.jpg" alt="">
			<img class="hide" src="assets/images/sp-bg-6.jpg" alt="">
			<img class="hide" src="assets/images/sp-bg-7.jpg" alt="">
			<img class="hide" src="assets/images/sp-bg-8.jpg" alt="">
			<img class="hide" src="assets/images/sp-bg-9.jpg" alt="">
			<img class="hide" src="assets/images/sp-bg-10.jpg" alt="">
			<img class="hide" src="assets/images/sp-bg-11.jpg" alt="">
			<img class="hide" src="assets/images/sp-bg-12.jpg" alt="">
		</div>

		<main property="mainContentOfPage" resource="#wb-main" typeof="WebPageElement">
			<div class="sp-hb">
				<div class="sp-bx col-xs-12">

					<h1 property="name" class="wb-inv">Canada.ca</h1>

					<!-- <div class="row">
						<div class="col-xs-11 col-md-8" property="publisher" resource="#wb-publisher" typeof="GovernmentOrganization">
                            <img src="assets/images/Canada-logo.svg" [attr.alt]="'menu.header.image.logo' | translate"/>
						</div>
					</div> -->

					<div class="row">
						<div class="col-xs-11 col-md-8" property="publisher" resource="#wb-publisher" typeof="GovernmentOrganization">
							<img src="assets/gcweb/assets/NRCan.svg" width="283" alt="Government of Canada" property="logo"><span class="wb-inv"> / <span lang="fr">Gouvernement du Canada</span></span>
							<meta property="name" content="Government of Canada">
							<meta property="name" lang="fr" content="Gouvernement du Canada">
							<meta property="areaServed" typeof="Country" content="Canada">
						</div>
					</div>

					<!-- <div class="row">
						<section class="col-xs-6 text-right">
                            <h2 class="wb-inv">Government of Canada</h2>
                            <p>
								<a [attr.href]="requestedUrl" class="btn btn-primary" (click)="onClickButton('en')" [attr.translate]="'no'">English</a>
							</p>
                        </section>
                        <section class="col-xs-6" lang="fr">
                            <h2 class="wb-inv">Gouvernement du Canada</h2>
                            <p>
								<a [attr.href]="requestedUrl" class="btn btn-primary" (click)="onClickButton('fr')" [attr.translate]="'no'">Fran&ccedil;ais</a>
							</p>
                        </section>
					</div> -->

					<div class="row wb-eqht-grd">
						<section class="col-sm-6 text-sm-right">
							<h2 class="hght-inhrt h4">Open Science and Technology Repository</h2>
							<p>
								<a [attr.href]="requestedUrl" class="btn btn-primary" (click)="onClickButton('en')" [attr.translate]="'no'">English</a>
							</p>
						</section>
							<section class="col-sm-6" lang="fr">
							<h2 class="hght-inhrt h4">Dépôt ouvert de la science et de la technologie</h2>
							<p>
								<a [attr.href]="requestedUrl" class="btn btn-primary" (click)="onClickButton('fr')" [attr.translate]="'no'">Fran&ccedil;ais</a>
							</p>
						</section>
					</div>

				</div>
				<div class="sp-bx-bt col-xs-12">
					<div class="row">
						<div class="col-xs-7 col-md-8">
							<a href="https://www.canada.ca/en/transparency/terms.html" class="sp-lk">Terms and conditions</a> <span class="glyphicon glyphicon-asterisk"></span> <a href="https://www.canada.ca/fr/transparence/avis.html" class="sp-lk" lang="fr">Avis</a>
						</div>
						<!-- <div class="col-xs-5 col-md-4 text-right mrgn-bttm-md">
							<img src="assets/images/wmms-spl.svg" width="127" [attr.alt]="'menu.header.image.logo' | translate"/>
						</div> -->

						<div class="col-xs-5 col-md-4 text-right mrgn-bttm-md">
							<img src="assets/images/wmms-blk.svg" width="127" alt="Symbol of the Government of Canada"><span class="wb-inv"> / <span lang="fr">Symbole du gouvernement du Canada</span></span>
						</div>

					</div>
				</div>
			</div>
		</main>


<!-- the following scripts are provided by welcome-page.component.ts -->

<!-- <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js" integrity="sha384-rY/jv8mMhqDabXSo+UCggqKtdmBfd3qC2/KvyTDNQ6PcUJXaxK1tMepoQda4g5vB" crossorigin="anonymous"></script>

<script src="https://wet-boew.github.io/themes-dist/GCWeb/wet-boew/js/wet-boew.min.js"></script><span id="wb-rsz" class="wb-init">&nbsp;</span>

<script src="https://wet-boew.github.io/themes-dist/GCWeb/GCWeb/js/theme.min.js"></script>

<script src="https://wet-boew.github.io/themes-dist/GCWeb/wet-boew/js/i18n/en.min.js"></script> -->

	</body>
</html>

