
<div gcweb class="container">
    <div class="col-md-12">
            <!--<h1 *ngIf="!adminSearch" property="name" id="wb-cont">{{'search.form.search' | translate}}</h1> -->
            <h1 *ngIf="!adminSearch" property="name" id="wb-cont">{{"home.button.advanced-search"| translate}}</h1>
            <h1 *ngIf="adminSearch" property="name" id="wb-cont">{{'admin.search.breadcrumbs' | translate}}</h1>
    </div>
    <div class="col-md-12">
          <app-dynamic-filters></app-dynamic-filters>
    </div>

    <div class="col-md-12">
          <h3 style ="color: #284162; text-decoration: underline; cursor: pointer;"
              title="{{'search.geospatial.showmaptip'|translate}}"
              (click)="toggleMapVisibility()">{{showHideMapnLabel}}</h3>
    </div>
    <!-- Geo spatial -->
        <div class="col-md-12" *ngIf="isMapVisible">
        <geo-search-page (geoChangeEvent) = "onGeoChanged($event)"></geo-search-page>
        </div>


        <div class="col-md-12 mrgn-bttm-lg mrgn-lft-sm mrgn-rght-sm">
            <div class="col-md-12 p-0 ">
                <!--<h2>{{ 'home.search-form.head' | translate }}</h2> -->
                <label class="wb-inv" for="main-searchrepository">{{ 'home.search-form.head' | translate }}</label>
                <div class="input-group">
                        <!-- <input (keydown.enter)="applyQuery(repositorySearch.value)" #repositorySearch type="text" class="form-control" [value]="mainSearchValue" id="main-searchrepository" />-->
                        <input (keydown.enter)="applyQuery(repositorySearch.value)" #repositorySearch type="text" class="form-control d-none" [value]="mainSearchValue" id="main-searchrepository" />
                        <span class="input-group-btn">
                            <button (keydown.enter)="applyQuery(repositorySearch.value)" (keydown.space)="applyQuery(repositorySearch.value)" (click)="applyQuery(repositorySearch.value)" class="btn btn-primary" value="submit" type="button">
                                <span class="button-text"><i class="fa fa-search mrgn-rght-sm"></i>
                                     {{ 'search.form.search' | translate }}
                                </span>
                              <!-- <span class="wb-inv">{{ 'home.search-form.placeholder' | translate }}
                              </span> -->
                            </button>
                        </span>
                </div>
            </div>
        </div>

    <hr class="solid">
    <br/>
    <div gcweb class="row" *ngIf="isResultsVisible">

        <!--
        <ds-comcol-search-filter *ngIf="searchEnabled && (searchOptions$ | async)?.query"
          [query]="(searchOptions$ | async)?.query"
          [(scope)]="currentScope"
          [inPlaceSearch]="inPlaceSearch"
          (submitSearch)="onSeachSubmit($event)">
        </ds-comcol-search-filter>
        -->

        <!-- <ng-container [ngTemplateOutlet]="searchFilters"></ng-container> -->
        <div class="col-md-4 pull-left" ds-search-sidebar id="search-sidebar"
        [resultCount]="(resultsRD$ | async)?.payload?.totalElements"
        [useGcWeb]="true"
        [searchOptions]="(searchOptions$ | async)"
        [sortOptions]="(sortOptions$ | async)"
        [inPlaceSearch]="inPlaceSearch"
        [geoQuery]="getGeoData()"></div>

        <!-- <div gc-search-filters class="col-md-4 pull-left"></div> -->
        <div class="col-md-8 pull-right mrgn-bttm-lg">


            <ng-container [ngTemplateOutlet]="searchResults"></ng-container>
        </div>
    </div>
</div>


<ng-template #searchResults>
    <h2 class="h4">{{'search.results.head' | translate}}</h2>

    <div ds-search-settings [searchOptions]="searchOptions$ | async" [sortOptions]="sortOptions$ | async" [useGcWeb]="true" class="row well well-small mrgn-lft-md mrgn-rght-md"></div>

    <ds-search-results [searchResults]="resultsRD$ | async"
    [searchConfig]="searchOptions$ | async"
    [configuration]="configuration$ | async"
    [useGcWeb]="true"
    disableHeader= "true"
    [context]="context"></ds-search-results>

</ng-template>
