<div #sdRef="ngbDropdown" ngbDropdown display="dynamic" placement="bottom-right" class="w-100">
  <div class="position-relative right-addon">

    <span ngbDropdownToggle class="position-absolute scrollable-dropdown-toggle"></span>
    <!-- OSPR - This input needs work, there's no way to go down the list of item with the keyboard  \
  FOSRC - added translate pipe to model.placeholder
  - changed [value]="(currentValue | async)" to [value]="selectedValueFormatter() | translate"
  -->
    <input class="form-control"
           [attr.aria-label]="model.placeholder | translate"
           [attr.autoComplete]="model.autoComplete"
           [class.is-invalid]="showErrorMessages"
           [class.scrollable-dropdown-input]="!model.readOnly"
           [id]="id"
           [name]="model.name"
           [readonly]="true"
           [type]="model.inputType"
           [value]="(currentValue | async)"
           (blur)="onBlur($event)"
           (click)="$event.stopPropagation(); openDropdown(sdRef);"
           (focus)="onFocus($event);openDropdown(sdRef);"
           (keypress)="$event.preventDefault()">
  </div>

  <div ngbDropdownMenu
       class="dropdown-menu scrollable-dropdown-menu w-100"
       aria-haspopup="true"
       aria-expanded="false"
       [attr.aria-label]="model.placeholder">
    <div class="scrollable-menu"
         [attr.aria-label]="model.placeholder"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
         (scrolled)="onScroll()"
         [scrollWindow]="false">

      <button class="dropdown-item disabled" *ngIf="optionsList && optionsList.length == 0">{{'form.no-results' | translate}}</button>
      <!-- FOSRC added translate pipe to results of "inputFormatter(listEntry) and also to title attribute" also added "role="option" [attr.id]="listEntry.display == (currentValue|async) ? ('combobox_' + id + '_selected') : null"> from github" -->
      <button class="dropdown-item collection-item text-truncate" *ngFor="let listEntry of optionsList" (click)="onSelect(listEntry); sdRef.close()" (mousedown)="onSelect(listEntry); sdRef.close()" title="{{ listEntry.display | translate}}" role="option">
        {{inputFormatter(listEntry) | translate}}
      </button>
      <div class="scrollable-dropdown-loading text-center" *ngIf="loading"><p>{{'form.loading' | translate}}</p></div>
    </div>

    </div>
  </div>


