<!--Report a problem section start -->
<ng-container *ngIf="translate.currentLang === 'en'">
  <div class="pagedetails">
    <footer>
      <h2 class="wb-inv">Page details</h2>
      <div class="row">
        <div class="col-sm-8 col-md-9 col-lg-9">
          <div>
          <!-- <div data-ajax-replace="../assets/gcweb/html/report-problem-en.html"> -->
            <div class="row row-no-gutters">
              <div class="col-sm-9 col-md-6 col-lg-5">
                <a class="btn btn-default btn-block" href="https://www.canada.ca/en/report-problem.html">Report a
                  problem on this page</a>
              </div>
            </div>
          </div>
        </div>
        <div class="wb-share col-sm-4 col-md-3" data-wb-share='{"lnkClass": "btn btn-default btn-block"}'>
        </div>
        <div class="col-xs-12">
          <dl id="wb-dtmd">
            <dt>Date modified:</dt>
            <dd><time property="dateModified">{{lastModified | date: 'yyyy-MM-dd'}}</time></dd>
          </dl>
        </div>
      </div>
    </footer>
  </div>
</ng-container>
<ng-container *ngIf="translate.currentLang === 'fr'">
  <div class="pagedetails">
    <!--Report a problem section start -->
    <footer>
      <h2 class="wb-inv">Détails de la page</h2>
      <div class="row">
        <div class="col-sm-8 col-md-9 col-lg-9">
          <div>
            <!-- <div data-ajax-replace="../assets/gcweb/html/report-problem-fr.html"> -->
            <div class="row row-no-gutters">
              <div class="col-sm-9 col-md-6 col-lg-5">
                <a class="btn btn-default btn-block" href="https://www.canada.ca/fr/signaler-probleme.html">Signaler un problème ou une erreur sur cette page</a>
              </div>
            </div>
          </div>
        </div>
        <div class="wb-share col-xs-12 col-sm-4 col-md-3" data-wb-share='{"lnkClass": "btn btn-default btn-block"}'>
        </div>
        <div class="col-xs-12">
          <dl id="wb-dtmd">
            <dt>Date de modification&#160;:</dt>
            <dd><time property="dateModified">{{lastModified | date: 'yyyy-MM-dd'}}</time></dd>
          </dl>
        </div>
      </div>
    </footer>
    <!--Report a problem section end -->
  </div>
</ng-container>