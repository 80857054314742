<!-- <form class="w-100" [formGroup]="formData" (ngSubmit)="submitForm(formData.value)">
  <label for="browseby">{{'browse.startsWith.text' | translate}}</label>
  <div class="row">
    <div class="form-group input-group col-8 col-sm-12 col-md-6">
      <input id = "browseby" autocomplete="on" class="form-control" placeholder="{{'browse.startsWith.type_text' | translate}}" type="text" name="startsWith" formControlName="startsWith" [value]="getStartsWith()" />
      <span class="input-group-append">
        <button class="btn btn-primary" type="submit"><span aria-hidden="true" class="fas fa-book-open"></span> {{'browse.startsWith.submit' | translate}}</button>
      </span>
    </div>
  </div>
</form> -->

<form [formGroup]="formData" (ngSubmit)="submitForm(formData.value)" id="searchForm">
  <label for="btngrp-browse" class="wb-inv">{{'browse.startsWith.label' | translate}}</label>
  <div class="input-group">
     <input type="text" name="startsWith" formControlName="startsWith" [value]="getStartsWith()" class="form-control mrgn-tp-md" id="btngrp-browse" placeholder="{{'browse.startsWith.type_text' | translate}}" />
     <span class="input-group-btn">
     <button class="btn btn-primary mrgn-tp-md" type="submit">{{'browse.startsWith.submit' | translate}}</button>
     </span>
  </div>
</form>