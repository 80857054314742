<div class="item-page-field">
  <div class="simple-view-element form-group" [class.d-none]="isHidden">
    <span class="simple-view-element-header h3" *ngIf="label">{{ label|translate }}</span>
    <div  class="simple-view-element-body">
      <span>
        {{ [value] }}  <a href="{{[mailtoLink]}}" class="dont-break-out" > {{[mailtoLabel]}} </a> {{ [valuePost] }}
      </span>

    </div>

  </div>

</div>

