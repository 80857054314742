<div customFosrc>
  <div class="modal-header">{{ headerLabel | translate:{dsoName: dso?.name} }}
    <button type="button" class="close" (click)="close()" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ infoLabel | translate:{dsoName: dso?.name} }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="cancel btn btn-outline-secondary" (click)="cancelPressed()" aria-label="Cancel">
      <span aria-hidden="true" class="fas fa-times"></span> {{ cancelLabel | translate:{dsoName: dso?.name} }}
    </button>
    <button type="button" class="confirm btn btn-{{brandColor}}" (click)="confirmPressed()" aria-label="Confirm" ngbAutofocus>
      <span aria-hidden="true" *ngIf="confirmIcon" class="{{confirmIcon}}"></span> {{ confirmLabel | translate:{dsoName: dso?.name} }}
    </button>
  </div>
</div>
