<div class="d-flex flex-row">
  <!--FOSRC change start-->
  <h1 class="item-page-title-field mr-auto">
    <!-- {{'publication.page.titleprefix' | translate}} -->
    <ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])" [separator]="'/'"></ds-metadata-values>
  </h1>
  <!--FOSRC change end (h2 is converted to h1)-->
  <div class="pl-2">
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'publication.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ng-container *ngIf="!mediaViewer.image">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-thumbnail [thumbnail]="object?.thumbnail | async"></ds-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <ng-container *ngIf="mediaViewer.image">
      <ds-media-viewer [item]="object" [videoOptions]="mediaViewer.video"></ds-media-viewer>
    </ng-container>

    <!-- conditional show althernative download -->
    <!--<ng-container *ngIf="displayLarge"> -->
    <ds-item-page-labellink-field
      [field]="'nrcan.publication.externaldownloadurl'"
      [item]="object"
      [label]="'item.page.files'"
      [hideIfEmpty]="true"
      [conditional]="true"
      (shouldHide)="handleShouldHide($event)">
    </ds-item-page-labellink-field>
    <!-- </ng-container> -->
    <!-- conitinoal hide file download -->
    <ng-container *ngIf="!displayLarge">
    <ds-item-page-file-section [item]="object"></ds-item-page-file-section>
    </ng-container>
    <!-- for mailto -->
    <ds-item-page-withemail-field [item]="object"
      [field]="'nrcan.pricedpublication'"
      [label]="'item.page.files'"
      [hideIfEmpty]="true">
    </ds-item-page-withemail-field>
    <ds-item-page-labellink-field [item]="object"
      [field]="'dc.relation.ispartof'"
      [label]="'item.page.relationships.isPartOf'"
      [hideIfEmpty]="true">
    </ds-item-page-labellink-field>
    <ds-item-page-date-field [item]="object"></ds-item-page-date-field>
    <ds-item-page-sponsorship-field [item]="object"></ds-item-page-sponsorship-field>
    <ds-item-page-govdoc-field [item]="object"></ds-item-page-govdoc-field>
    <ds-item-page-isbn-field [item]="object"></ds-item-page-isbn-field>
    <ds-item-page-pubmed-field [item]="object"></ds-item-page-pubmed-field>
    <ds-item-page-issn-field [item]="object"></ds-item-page-issn-field>
    <ds-item-page-organization-field [item]="object"></ds-item-page-organization-field>
    <ds-item-page-other-field [item]="object"></ds-item-page-other-field>
    <ds-item-page-keywords-field [item]="object"></ds-item-page-keywords-field>
    <ds-metadata-representation-list class="ds-item-page-mixed-author-field"
      [parentItem]="object"
      [itemType]="'Person'"
      [metadataFields]="['dc.contributor.author', 'dc.creator']"
      [label]="'relationships.isAuthorOf' | translate">
    </ds-metadata-representation-list>
    <ds-generic-item-page-field [item]="object"
      [fields]="['journal.title']"
      [label]="'publication.page.journal-title'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['journal.identifier.issn']"
      [label]="'publication.page.journal-issn'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['journalvolume.identifier.name']"
      [label]="'publication.page.volume-title'">
    </ds-generic-item-page-field>
    <!--
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.publisher']"
      [label]="'publication.page.publisher'">
    </ds-generic-item-page-field>-->
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isProjectOfPublication'"
      [label]="'relationships.isProjectOf' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isOrgUnitOfPublication'"
      [label]="'relationships.isOrgUnitOf' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isJournalIssueOfPublication'"
      [label]="'relationships.isJournalIssueOf' | translate">
    </ds-related-items>
    <!-- abstract -->
    <!--<ds-item-page-abstract-field [item]="object"></ds-item-page-abstract-field>-->
    <ds-item-page-collapsable-field [item]="object"
     [label] ="'item.page.abstract'"
     [field] ="'dc.description.abstract'"
     [postfix]="'abst'">
    </ds-item-page-collapsable-field>
    <!-- -->
    <ds-item-page-collapsable-field [item]="object"
      [label]="'publication.page.description'"
      [field]="'dc.description'"
      [postfix]="'desc'">
    </ds-item-page-collapsable-field>

    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.subject']"
      [separator]="','"
      [label]="'item.page.subject'">
    </ds-generic-item-page-field>
    <!--DOI -->
    <ds-item-page-doi-field [item]="object" >
    </ds-item-page-doi-field>
    <!-- -->
    <!-- Citation -->
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.identifier.citation']"
      [separator]="','"
      [label]="'item.page.citation'">
    </ds-generic-item-page-field>
    <!--
    <ds-item-page-citation-field [item]="object">
    </ds-item-page-citation-field>-->

    <!-- Publisher-->
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.publisher']"
      [separator]="','"
      [label]="'item.page.publisher'">
    </ds-generic-item-page-field>

    <!-- Summary -->
    <ds-item-page-collapsable-field [item]="object"
     [label] ="'Plain Language Summary'"
     [field] ="'nrcan.summary'"
     [postfix]="'sumr'">
    </ds-item-page-collapsable-field>
    <!-- -->


    <!-- Meeting-->
    <ds-generic-item-page-field [item]="object"
      [fields]="['nrcan.meeting.name']"
      [separator]="','"
      [label]="'Meeting'">
    </ds-generic-item-page-field>
    <!-- -->

    <!-- Area -->
    <ds-generic-item-page-field [item]="object"
      [fields]="['nrcan.area']"
      [separator]="','"
      [label]="'area.listelement.badge'">
    </ds-generic-item-page-field>
    <!-- -->


    <!-- Polygon -->
    <ds-item-page-polygon-field [item]="object">
    </ds-item-page-polygon-field>
    <!-- -->

    <!-- Language -->
    <ds-item-page-iso-field [item]="object"></ds-item-page-iso-field>

    <!-- -->
    <ds-item-page-uri-field [item]="object"
      [fields]="['dc.identifier.uri']"
      [label]="'item.page.uri'">
    </ds-item-page-uri-field>

    <ds-item-page-labellink-field [item]="object"
      [field]="'nrcan.publication.externalurl'"
      [label]="'item.page.externalurl'">
    </ds-item-page-labellink-field>
    <!-- relationship-->
    <!-- istranslationof-->
    <ds-item-page-labellink-field [item]="object"
      [field]="'dc.relation.istranslationof'"
      [label]="'item.page.relationships.isTranslationOf'"
      [hideIfEmpty]="true">
    </ds-item-page-labellink-field>

    <!-- isrelatedto-->
    <ds-item-page-labellink-field [item]="object"
      [field]="'dc.relation.isrelatedto'"
      [label]="'item.page.relationships.isRelatedTo'"
      [hideIfEmpty]="true">
    </ds-item-page-labellink-field>

    <!-- is version of -->
    <ds-item-page-labellink-field [item]="object"
      [field]="'dc.relation.isversionof'"
      [label]="'item.page.relationships.isversionof'"
      [hideIfEmpty]="true">
    </ds-item-page-labellink-field>

    <!-- has version -->
    <ds-item-page-labellink-field [item]="object"
      [field]="'dc.relation.hasversion'"
      [label]="'item.page.relationships.hasversion'"
      [hideIfEmpty]="true">
    </ds-item-page-labellink-field>

    <!-- replaces -->
    <ds-item-page-labellink-field [item]="object"
      [field]="'dc.relation.replaces'"
      [label]="'item.page.relationships.replaces'"
      [hideIfEmpty]="true">
    </ds-item-page-labellink-field>

    <!-- is replaced by -->
    <ds-item-page-labellink-field [item]="object"
      [field]="'dc.relation.isreplacedby'"
      [label]="'item.page.relationships.isreplacedby'"
      [hideIfEmpty]="true">
    </ds-item-page-labellink-field>

    <!-- price pub -->
    <ds-item-page-withemail-field [item]="object"
      [field]="'nrcan.printavailable'"
      [label]="'item.page.pricedpublication'"
      [hideIfEmpty]="true">
    </ds-item-page-withemail-field>

    <ds-item-page-collections [item]="object"></ds-item-page-collections>
    <!-- Type -->
    <ds-item-page-lang-dependent-field [item]="object"
      [field]="'dc.type'"
      [label]="'item.page.type'">
    </ds-item-page-lang-dependent-field>
    <!-- -->

    <div>
      <a class="btn btn-outline-primary" role="button" [routerLink]="[itemPageRoute + '/full']">
        <span aria-hidden="true" class="fas fa-info-circle"></span> {{"item.page.link.full" | translate}}
      </a>
    </div>

  </div>
</div>
