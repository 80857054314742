<div class="item-page-field">
  <!-- <ds-metadata-values [mdValues]="item?.allMetadata(fields)" [separator]="separator" [label]="label"></ds-metadata-values> -->
  <div class="simple-view-element  form-group"  [class.d-none]="isHidden">
    <span class="simple-view-element-header h3" *ngIf="label">{{ label | translate }}</span>
    <ds-truncatable [id]="idEx" [useShowMore]="true">
    <div  class="simple-view-element-body">
        <!--<span [attr.lang]="field.language" [innerHTML]="field.value"></span> -->

        <ds-truncatable-part [id]="idEx" [minLines]="3" class="item-list-abstract">
          <span [ngClass]="{'text-muted': !item.firstMetadataValue(field)}"
                [innerHTML]="value
                 || ('mydspace.results.no-abstract' | translate)"></span>
        </ds-truncatable-part>
        <!--
        <span>{{item?.firstMetadata(field)}}</span>
        -->
    </div>
    </ds-truncatable>
  </div>
</div>
