<ng-container [formGroup]="group">
    <div [id]="id"
         [formArrayName]="model.id"
         [ngClass]="getClass('element', 'control')">

      <!-- Draggable Container -->
      <div cdkDropList cdkDropListLockAxis="y" (cdkDropListDropped)="moveSelection($event)">
        <!-- Draggable Items -->
        <div *ngFor="let groupModel of model.groups; let idx = index"
             role="group"
             [formGroupName]="idx"
             [ngClass]="[getClass('element', 'group'), getClass('grid', 'group')]"
             cdkDrag
             cdkDragHandle
             [cdkDragDisabled]="dragDisabled"
             [cdkDragPreviewClass]="'ds-submission-reorder-dragging'">
          <!-- Item content -->
          <div class="drag-handle" [class.invisible]="dragDisabled" tabindex="0">
            <span aria-hidden="true" class="drag-icon fas fa-grip-vertical fa-fw" [title]="'form.drag' | translate"></span>
            <!-- <span class="wb-inv">{{'form.drag' | translate}}</span> -->
          </div>
          <ng-container *ngTemplateOutlet="startTemplate?.templateRef; context: groupModel"></ng-container>
          <ds-dynamic-form-control-container *ngFor="let _model of groupModel.group"
                                             [bindId]="false"
                                             [formGroup]="group"
                                             [context]="groupModel"
                                             [group]="control.get([idx])"
                                             [hidden]="_model.hidden"
                                             [layout]="formLayout"
                                             [model]="_model"
                                             [templates]="templates"
                                             [ngClass]="[getClass('element', 'host', _model), getClass('grid', 'host', _model)]"
                                             (dfBlur)="onBlur($event)"
                                             (dfChange)="onChange($event)"
                                             (dfFocus)="onFocus($event)"
                                             (ngbEvent)="onCustomEvent($event, null, true)"></ds-dynamic-form-control-container>

          <ng-container *ngTemplateOutlet="endTemplate?.templateRef; context: groupModel"></ng-container>
        </div>
      </div>



    </div>

</ng-container>
