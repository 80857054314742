<!-- <form class="w-100" [formGroup]="formData" (ngSubmit)="submitForm(formData.value)">
  <label for="browse-date-field">{{'browse.startsWith.jump' | translate}}</label>
  <div class="form-group input-group col-12 col-md-6 pt-1 pt-md-0 pl-0" title="{{'browse.startsWith.type_date' | translate}}">
    <input autocomplete="on" id="browse-date-field" class="form-control" placeholder="{{'browse.startsWith.type_date' | translate}}"
      type="text" name="startsWith" formControlName="startsWith" [value]="getStartsWith() ? getStartsWith() : ''" />
    <span class="input-group-append">
      <button class="btn btn-primary" type="submit"><span aria-hidden="true" class="fas fa-book-open"></span>
        {{'browse.startsWith.submit' | translate}}</button>
    </span>
  </div>
</form> -->

<label for="btngrp-browse" class="wb-inv">{{'browse.startsWith.type_date' | translate}}</label>
<form [formGroup]="formData" (ngSubmit)="submitForm(formData.value)" id="searchForm">
  <div class="input-group">
    <input id="browse-date-field" name="startsWith" formControlName="startsWith" [value]="getStartsWith() ? getStartsWith() : ''" 
      type="text" class="form-control mrgn-tp-md" id="btngrp-browse"
      placeholder="{{'browse.startsWith.type_date' | translate}}" />
    <span class="input-group-btn">
      <button class="btn btn-primary mrgn-tp-md" type="submit">{{'browse.startsWith.submit' | translate}}</button>
    </span>
  </div>
</form>


<!-- <form class="w-100" [formGroup]="formData" (ngSubmit)="submitForm(formData.value)">
  <div class="row">
    <span class="col-12 font-weight-bold mb-1">
      <label for="browse-date-field">{{'browse.startsWith.jump' | translate}}</label>
    </span>
    <div class="col-6 col-md-2">
      <select id="year-select" class="form-control" (change)="setStartsWithYearEvent($event)" [attr.aria-label]="'browse.startsWith.choose_year.label' |translate">
        <option [value]="-1" [selected]="!startsWithYear">
          {{'browse.startsWith.choose_year' | translate}}
        </option>
        <option *ngFor="let option of startsWithOptions"
                [value]="option"
                [selected]="option === startsWithYear ? 'selected': null">
          {{option}}
        </option>
      </select>
    </div>
    <div class="col-6 col-md-2">
      <select id="month-select" class="form-control" (change)="setStartsWithMonthEvent($event)" [attr.aria-label]="'browse.startsWith.months.none.label' |translate">
        <option *ngFor="let option of monthOptions"
                [value]="option"
                [selected]="option === startsWithMonth ? 'selected': null">
          {{'browse.startsWith.months.' + option | translate}}
        </option>
      </select>
    </div> 
    <div class="form-group input-group col-12 col-md-6 pt-1 pt-md-0">
      <input autocomplete="on" id="browse-date-field" class="form-control" placeholder="{{'browse.startsWith.type_date' | translate}}" type="text" name="startsWith" formControlName="startsWith" [value]="getStartsWith() ? getStartsWith() : ''" />
      <span class="input-group-append">
        <button class="btn btn-primary" type="submit"><span aria-hidden="true" class="fas fa-book-open"></span> {{'browse.startsWith.submit' | translate}}</button>
      </span>
    </div>
  </div>
</form> -->
