<ng-container *ngVar="searchOptions as config">
        <ng-container *ngIf="!useGcWeb; else GcWebTemplate">
        <div class="row">
        <div class="result-order-settings">
        <ds-sidebar-dropdown
                *ngIf="config?.sort"
                [id]="'search-sidebar-sort'"
                [label]="'search.sidebar.settings.sort-by'"
                (change)="reloadOrder($event)"
        >
            <option *ngFor="let sortOption of sortOptions"
                    [value]="sortOption.field + ',' + sortOption.direction.toString()"
                    [selected]="sortOption.field === config?.sort.field && sortOption.direction === (config?.sort.direction)? 'selected': null">
                {{'sorting.' + sortOption.field + '.' + sortOption.direction | translate}}
            </option>
        </ds-sidebar-dropdown>
        </div>
        <ds-page-size-selector></ds-page-size-selector>
        </div>
    </ng-container>

    <ng-template #GcWebTemplate>

        <ng-container *ngIf="!onComColPage">

            <div class="col-xl-8 col-lg-7 col-md-12 pull-left">
                <div class="input-group">
                    <label for="Sortby">{{'search.sidebar.settings.sort-by' | translate}}</label>
                    <select (change)="reloadOrder($event)" class="form-control" id="Sortby" name="Sortby">
                        
                        <option 
                            *ngFor="let sortOption of sortOptions" 
                            [attr.label]="('sorting.' + sortOption?.field + '.' +sortOption?.direction) | translate"
                            [value]="sortOption?.field + ',' + sortOption?.direction.toString()"
                            [selected]="sortOption?.field === config?.sort?.field && sortOption?.direction === (config?.sort?.direction)? 'selected': null"
                            >
                            {{('sorting.' + sortOption?.field + '.' +sortOption?.direction) | translate}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-xl-4 col-lg-5 col-md-12 pull-right" ds-page-size-selector [useGcWeb]="true"></div>

        </ng-container>

        <ng-container *ngIf="onComColPage">

            <div class="col-xl-8 col-lg-7 col-md-8 col-sm-12 pull-left">
                <div class="col-xl-7 col-lg-8 col-md-10 p-0">
    
                    <div class="input-group">
                        <label for="Sortby">{{'search.sidebar.settings.sort-by' | translate}}</label>
                        <select (change)="reloadOrder($event)" class="form-control" id="Sortby" name="Sortby">
                            
                            <option 
                                *ngFor="let sortOption of sortOptions" 
                                [attr.label]="('sorting.' + sortOption?.field + '.' +sortOption?.direction) | translate"
                                [value]="sortOption?.field + ',' + sortOption?.direction.toString()"
                                [selected]="sortOption?.field === config?.sort?.field && sortOption?.direction === (config?.sort?.direction)? 'selected': null"
                                >
                                {{('sorting.' + sortOption?.field + '.' +sortOption?.direction) | translate}}
                            </option>
                        </select>
                    </div>
    
                </div>
            </div>
            <div class="col-xl-4 col-lg-5 col-md-4 col-sm-12 pull-right">
    
                <div class="col-xl-9 col-lg-7 col-md-10 p-0" ds-page-size-selector [useGcWeb]="true"></div>
    
            </div>

        </ng-container>

    </ng-template>
</ng-container>

