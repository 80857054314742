<ng-container *ngIf="!useGcWeb else GcWebTemplate">
    <a *ngIf="isVisible | async" class="d-flex flex-row"
       [routerLink]="[searchLink]"
       [queryParams]="changeQueryParams" queryParamsHandling="merge">
      <span class="filter-value px-1">{{filterValue.label}}</span>
      <span class="float-right filter-value-count ml-auto">
                            <span class="badge badge-secondary badge-pill">{{filterValue.count}}</span>
                        </span>
    </a>
</ng-container>

<ng-template #GcWebTemplate>
    <a *ngIf="isVisible | async" 
    [routerLink]="[searchLink]"
    [queryParams]="changeQueryParams" queryParamsHandling="merge"
    class="list-group-item">{{filterValue.label}} <span class="badge badge-pill">{{filterValue.count}}</span> </a>
</ng-template>