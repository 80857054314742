<div class="card p-2 m-3 {{(comcolRole$ | async)?.name}}">

  <div class="card-body d-flex flex-column"
       *ngVar="group$ | async as group">
    <!-- OSPR changes to h5 to h2 -->
    <h2 class="w-100">
      {{'comcol-role.edit.' + (comcolRole$ | async)?.name + '.name' | translate}}
    </h2>
    <!-- changing h5 to h2 to align header organization -->

    <div class="mt-2 mb-2">
      <ds-alert [type]="'alert-info'" >{{'comcol-role.edit.' + (comcolRole$ | async)?.name + '.description' | translate}}</ds-alert>
    </div>

    <div class="d-flex flex-md-row justify-content-between flex-column">
      <div class="w-100 d-flex align-items-center">
        <ds-loading *ngIf="!(groupRD$ | async)"></ds-loading>
        <div *ngIf="hasNoGroup$ | async">
          {{'comcol-role.edit.no-group' | translate}}
        </div>
        <div *ngIf="hasAnonymousGroup$ | async">
          {{'comcol-role.edit.' + (comcolRole$ | async)?.name + '.anonymous-group' | translate}}
        </div>
        <a *ngIf="hasCustomGroup$ | async"
           routerLink="{{editGroupLink$ | async}}">
          {{group.name}}
        </a>
      </div>

      <div class="flex-shrink-0">
        <button *ngIf="hasNoGroup$ | async"
             class="btn btn-primary create"
             (click)="create()">
          <span class="fas fa-plus" aria-hidden="true"></span> {{'comcol-role.edit.create' | translate}}
        </button>
        <button *ngIf="hasAnonymousGroup$ | async"
             class="btn btn-primary restrict"
             (click)="create()">
          {{'comcol-role.edit.restrict' | translate}}
        </button>
        <!--OSPR UI-13 issue 291-->
        <button *ngIf="hasCustomGroup$ | async"
             class="btn btn-danger delete"
             (click)="$event.preventDefault(); confirmDelete(content)">
          <span class="fas fa-trash" aria-hidden="true"></span> {{'comcol-role.edit.delete' | translate}}
        </button>
      </div>

    </div>


  </div>

</div>

<!--OSPR UI-13 issue 291-->
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger">{{'comcol-role.delete.confirm.title' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{'comcol-role.delete.confirm.info' | translate}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('cancel')">{{'comcol-role.delete.confirm.cancel' | translate}}</button>
    <button type="button" class="btn btn-danger" (click)="c('ok')">{{'comcol-role.delete.confirm.submit' | translate}}</button>
  </div>
</ng-template>
