<div #paginator *ngIf="currentPageState == undefined || currentPageState == (currentPage$|async)" [id]="'p-' + id">
  <div *ngIf="(!hidePaginationDetail && collectionSize > 0) || !hideGear" class="pagination-masked clearfix top">
    <ng-container *ngIf="!useGcWebTop; else gcWebTop">
      <div class="row">
        <div *ngIf="!hidePaginationDetail && collectionSize > 0" class="col-auto pagination-info">
            <span class="align-middle hidden-xs-down">{{ 'pagination.showing.label' | translate }}</span>
            <span class="align-middle">{{ 'pagination.showing.detail' | translate:(getShowingDetails(collectionSize)|async)}}</span>
        </div>
        <div class="col">
          <div *ngIf="!hideGear" ngbDropdown #paginationControls="ngbDropdown" placement="bottom-right" class="d-inline-block float-right">
            <button class="btn btn-secondary" id="paginationControls" ngbDropdownToggle [title]="'pagination.options.description' | translate" [attr.aria-label]="'pagination.options.description' | translate">
              <span class="fas fa-cog" aria-hidden="true"></span>
            </button>
            <div id="paginationControlsDropdownMenu" aria-labelledby="paginationControls" ngbDropdownMenu>
              <h6 class="dropdown-header">{{ 'pagination.results-per-page' | translate}}</h6>
              <button class="dropdown-item" *ngFor="let item of pageSizeOptions" (click)="doPageSizeChange(item)">
                <span [ngClass]="{'invisible': item != (pageSize$|async)}" class="fas fa-check" aria-hidden="true"></span> {{item}} </button>
              <h6 class="dropdown-header">{{ 'pagination.sort-direction' | translate}}</h6>
              <button class="dropdown-item" *ngFor="let direction of (sortDirections | dsKeys)" (click)="doSortDirectionChange(direction.value)">
                <span [ngClass]="{'invisible': direction.value !== (sortDirection$ |async)}" class="fas fa-check" aria-hidden="true"></span> {{'sorting.' + direction.key | translate}} </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #gcWebTop>
      <ng-container *ngIf="!hidePaginationDetail && collectionSize > 10">
        <p class="mrgn-tp-md">{{ 'pagination.showing.detail' | translate:(getShowingDetails(collectionSize)|async)}}</p>
      </ng-container>
    </ng-template>
  </div>
  <ng-content></ng-content>
  <!-- Ngb pagination has an issue with the last page, so overriding the last page is necessary  -->
  <ng-container *ngVar="((currentPage$|async) === ((((collectionSize - (collectionSize % ((pageSize$ | async) || 10) ) ) / ((pageSize$ | async) || 1) )) + 1)) as isLastPageSelected">
    <div *ngIf="shouldShowBottomPager |async" class="pagination justify-content-center clearfix bottom">
    <ngb-pagination [boundaryLinks]="paginationOptions.boundaryLinks"
                    [collectionSize]="collectionSize"
                    [disabled]="paginationOptions.disabled"
                    [ellipses]="paginationOptions.ellipses"
                    [maxSize]="(useGcWeb || isXs) ? ((isLastPageSelected && useGcWeb) ? 10 : 5) : paginationOptions.maxSize"
                    [page]="(currentPage$|async)"
                    (pageChange)="doPageChange($event)"
                    [pageSize]="(pageSize$ |async)"
                    [rotate]="paginationOptions.rotate"
                    [size]="(isXs)?'sm':paginationOptions.size">
                    <ng-template ngbPaginationNumber let-currentPage="currentPage" let-page>
                          <span class="wb-inv">{{page === currentPage ? '(current)' : ''}} {{ 'pagination.go.to' | translate }}</span>
                          {{page}}
                    </ng-template>
        <ng-template rel="prev" ngbPaginationPrevious>{{'pagination.button.prev' | translate}} </ng-template>
        <ng-template rel="next" ngbPaginationNext>{{'pagination.button.next' | translate}}</ng-template>
    </ngb-pagination>
  </div>
    </ng-container>
</div>
